import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull';
import moment from 'moment';
import 'moment-duration-format'

let timerCount = 0;
function CustomPlayer({src, thumbnailImg, duration, onEnded}) {
  const [state, setState ] = useState({
    playing: false,
    played: 0,
    playedSeconds: 0,
    loaded: 0, 
    loadedSeconds: 0, 
    mute: true,
    seeking: false,
    volume: 100,
    mute: false
  })
  const [ isSelectionInContain, setIsSelectionInContain ] = useState(false)
  const ref = useRef(null)
  const controlRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    let handler = (e) => {
      e.stopPropagation();
      if(containerRef.current.contains(e.target)){
      }
    }

    document.addEventListener('keypress', handler);

    return () => {
      document.removeEventListener('keypress', handler)
    }
  })

  const handleProgress = (event) => {
    if(!state.seeking){
      setState({...state, ...event})
    }
    if (timerCount > 2) {
      controlRef.current.style.opacity = 0;
      controlRef.current.style.transform = 'translateY(100%)';
      timerCount = 0;
    }
    if (controlRef.current.style.opacity == 1) {
      timerCount += 1;
    }
  }

  const handleSliderChange = (e) => {
    setState({...state, played: parseFloat(e.target.value / 100)})
  }

  const handleSliderBeforeChange = () => {
    setState({...state, seeking: true})
  } 

  const handleSliderAfterChange = (e) => {
    setState({...state, seeking: false})
    ref.current.seekTo(e.target.value / 100, 'fraction')
  }
  
  const handleVolumeChange = (e) => {
    setState({...state, 
      volume: parseFloat(e.target.value / 100),
      mute: e.target.value == 0 ? true : false
    })
  }

  const handleMouseMove = () => {
    controlRef.current.style.opacity = 1;
    controlRef.current.style.transform = 'translateY(0)';
    timerCount = 0;
  }

  const handleFullscreen = () => {
    screenfull.toggle(containerRef.current);
  }

  return (
    <div className='relative overflow-hidden' onMouseMove={handleMouseMove} ref={containerRef} onKeyDown={() => console.log('keyDown')}>
      <div 
        className='absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center group z-[5]' 
        onClick={() => setState({...state, playing: !state.playing})}
      >
        {
          !state.playing && 
          <button className={`flex bg-white text-sky-500 hover:text-sky-400 text-5xl z-10 rounded-full shadow-no1`} onClick={() => setState({...state, playing: !state.playing})}>
            <ion-icon name={`${state.playing ? 'pause' : 'play'}-circle`}></ion-icon>
          </button>
        }
      </div>
      <ReactPlayer
        ref={ref}
        url={src}
        playing={state.playing}
        controls={false}
        width={'100%'}
        height={'100%'}
        volume={state.volume}
        muted={state.mute}
        // light={'https://images.pexels.com/photos/11089963/pexels-photo-11089963.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'}
        onEnded={() => onEnded(true)}
        onProgress={handleProgress}
      />
      <div className='absolute flex flex-col bottom-0 w-full px-5 py-2 z-10 translate-y-full ease-in-out duration-300 bg-gradient-to-t from-[#00000090] to-transparent bg-opacity-20' ref={controlRef}>
        <div className='relative w-full h-1 bg-white bg-opacity-30 rounded-lg mb-2'>
          <div className='absolute h-1 bg-white bg-opacity-50 rounded-lg' style={{width: `${state.loaded*100}%`}}/>
          <div className='absolute h-1 bg-sky-500 rounded-lg' style={{width: `${state.played*100}%`}}/>
          <input 
            type="range" 
            name="range" 
            className="w-full h-1 bg-transparent appearance-none rounded-lg cursor-pointer absolute" 
            step={0.1} 
            max={100} 
            value={state.played*100} 
            onChange={handleSliderChange}
            onMouseDown={handleSliderBeforeChange}
            onMouseUpCapture={handleSliderAfterChange}
          />
        </div>
        <div className='xs:block md:hidden text-xs text-gray-100'>
          {moment.duration(state.playedSeconds, 'seconds').format('hh:mm:ss', {stopTrim: 'mm:ss'})} / {duration}
        </div>
        <div className='flex items-center xs:text-md md:text-2xl text-gray-100'>
          <div className='flex items-center gap-3 w-1/3'>
            <button className='hover:text-gray-50 flex hover:bg-sky-500 p-1 rounded' onClick={() => setState({...state, mute: !state.mute})}>
              <ion-icon name={`volume-${state.mute ? 'mute' : state.volume > 0.8 ? 'high' : state.volume < 0.3 ? 'low' : 'medium' }`}></ion-icon>
            </button>
            <input 
              type="range" 
              name="range" 
              className="xs:w-1/2 md:w-[100px] h-1 bg-gray-200 appearance-none rounded-lg cursor-pointer" 
              step={0.1} 
              min={0}
              max={100} 
              value={state.volume*100} 
              onChange={handleVolumeChange}
            />
            <div className='xs:hidden md:block text-xs text-gray-100'>
              {moment.duration(state.playedSeconds, 'seconds').format('hh:mm:ss', {stopTrim: 'mm:ss'})} / {duration}
            </div>
          </div>
          <div className='flex justify-center gap-4 items-center w-1/3'>
            <button className='flex hover:text-gray-50 hover:bg-sky-500 p-1 rounded'>
              <ion-icon name="play-back"></ion-icon>
            </button>
            <button className='flex hover:text-gray-50 hover:bg-sky-500 p-1 rounded' onClick={() => setState({...state, playing: !state.playing})}>
              <ion-icon name={`${state.playing ? 'pause' : 'play'}`}></ion-icon>
            </button>
            <button className='flex hover:text-gray-50 hover:bg-sky-500 p-1 rounded'>
              <ion-icon name="play-forward"></ion-icon>
            </button>
          </div>
          <div className='flex justify-end gap-4 items-center w-1/3'>
            <button className='flex hover:text-gray-50 hover:bg-sky-500 p-1 rounded'>
              <ion-icon name="settings"></ion-icon>
            </button>
            <button className='flex hover:text-gray-50 hover:bg-sky-500 p-1 rounded' onClick={handleFullscreen}>
              <ion-icon name="scan"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomPlayer