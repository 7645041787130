import React, { useState } from 'react'
import GenerateStar from 'components/GenerateStars';
import moment from 'moment';
import { ImgErrorEmployee } from 'components/NoImage';
import PopupConfirm from 'components/PopupConfirm';
import {MdOutlineEdit} from 'react-icons/md'
import axios from 'axios';
import Modal from 'components/Modal';
import RateInput from 'components/RateInput';

const ratingStars = (index) => {
  let starCount = 5 - index;
  let stars = []
  for(let i = 0; i < starCount; i++){ 
    stars.push(
      <ion-icon name="star" style={{color: 'gold', fontSize: 20}} key={`s${i}`}/>
    )
  }
  for(let i = 0; i < index; i++){
    stars.push(
      <ion-icon name="star-outline" style={{color: 'gold', fontSize: 20}} key={`c${i}`}/>
    )
  }
  return <>{stars}</>
} 
  
const reviewStars = (starCount) => {
  let stars = []
  for(let i = 0; i < starCount; i++ ){
    stars.push(
      <ion-icon name="star" style={{color: 'gold', fontSize: 15}} key={`r${i}`}/>
    )
  }
  return <>{stars}</>
}

function Review({data, refreshData}) {
  const [ showModal, setShowModal ] = useState(false)
  const [ editReview, setEditReview ] = useState(null)

  const submitDeleteReview = (review, ) => {
    // console.log(args)
    axios({
      method: 'DELETE',
      url: `coursereview/${review.id}`
    }).then((res) => {
      refreshData()
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleEditBtn = (review) => {
    setEditReview(review)
    setShowModal(true)
  }

  const handleSubmit = (review, starCount) => {
    if(editReview){
      axios({
        method: 'PUT',
        url: 'coursereview',
        data: {
          comment : review,
          rating : starCount,
          courseId : data.id,
          id: editReview.id
        }
      }).then((res) => {
        setShowModal(false);
        setEditReview(null)
        refreshData()
      }).catch((err) => {
        console.log(err)
      })
    }
    else{
      axios({
        method: 'POST',
        url: 'coursereview',
        data: {
          comment : review,
          rating : starCount,
          courseId : data.id,
        }
      }).then((res) => {
        setShowModal(false)
        refreshData()
      }).catch((err) => {
        console.log(err)
      })
    }
  }
  return (
    <div>
      <div className='flex justify-between items-center'>
        <div className='text-xl mb-3' >Үнэлгээ</div>
        <button type='button' className='text-sm font-medium bg-blue-500 text-white py-1 px-2 rounded' onClick={() => setShowModal(true)}>
          Үнэлгээ өгөх
        </button>
      </div>
        <div>
          {/* <ReviewRating reviews={data.course_review} rating={data.course_rating}/> */}
          <div className='flex gap-5'>
          <div className='w-1/5 flex flex-col justify-center items-center border rounded dark:border-slate-700'>
              <div className='xs:text-4xl sm:text-6xl' style={{color:'gold'}}>{data.course_rating.avg_rating ? parseFloat(data.course_rating.avg_rating).toFixed(1) : 0}</div>
              <div className='xs:hidden md:block'>
                <GenerateStar rating={data.course_rating.avg_rating} size={24} color='gold'/>
              </div>
          </div>
          <div className='w-4/5' >
            {
              data.course_rating.data.map((item,i) => (
                <div className='flex gap-5 items-center' key={i}>
                  <div className="w-2/3 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                    <div className="bg-blue-600 h-2.5 rounded-full" style={{width: `${parseInt(item.percent)}%`}}></div>
                  </div>
                  <div className='flex w-1/3'>{ratingStars(i)} <span className='ml-2'>{parseInt(item.percent)}%</span></div>
                </div>
              ))
            }
          </div>
        </div>
        <div className='border-b my-4 dark:border-slate-700'></div>
        <div className='p-2'>
          {
            data.course_review.map((item, i) => (
              <div className='flex mb-3' key={i}>
                <img
                  style={{width:'40px', heigth:'40px', objectFit:'cover', objectPosition:'center', overflow:'hidden', borderRadius:'50%'}} 
                  src={`${process.env.REACT_APP_ASSET_URL}${item.image}`}
                  className='mr-2 self-start'
                  onError={(e) => {e.target.error = null; e.target.src = ImgErrorEmployee}}
                
                />
                <div>
                  <div className='flex'>
                    <div>
                      <div className='text-gray-500 font-medium' style={{fontSize: '14px'}}>
                        {item.lastName} {item.firstName} 
                      </div>
                      <div className='text-gray-400 flex items-center'>
                        <div className='mr-1'>{reviewStars(item.rating)}</div> 
                        •
                        <div className='ml-1 text-sm'>{moment(item.createdAt).format('YYYY-MM-DD hh:mm')}</div>
                      </div>
                    </div>
                    {
                      item.myRating == 1 && 
                      <div className='flex self-start ml-3'>
                        <PopupConfirm onConfirm={() => submitDeleteReview(item)}>
                          <button className='text-red-600 p-1 rounded hover:bg-red-100 flex'><ion-icon name="trash-outline"></ion-icon></button>
                        </PopupConfirm>
                        <button className='text-slate-600 p-1 rounded hover:bg-red-100 flex' onClick={() => handleEditBtn(item)}>
                          <MdOutlineEdit/>
                        </button>
                      </div>
                    }
                  </div>
                  <div className='mt-2'>{item.comment}</div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <Modal title={editReview ? 'Үнэлгээ засах' : 'Үнэлгээ өгөх'} show={showModal} toggleShow={setShowModal} className='w-[400px]'>
        <RateInput edit={editReview} onSubmit={handleSubmit}/>
      </Modal>
    </div>
  )
}

export default Review