import React, { Fragment, useEffect, useState } from 'react'
import { ImgErrorEmployee } from 'components/NoImage'
import { Tab } from '@headlessui/react'
import QA from './components/QA'
import ContentSidebar from './contentSidebar'
import Review from './components/review'

function Details({data, refresh, handleChangeVideo, currentVideo, videoEnded, handleVideoEnded, handleTest, handleExamModal}) {
  const [ windowWidth, setWindowWidth ] = useState(window.innerWidth)
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    
  },[windowWidth])

  return (
    <div className='flex'>
      <div className="border-b border-gray-200 dark:border-gray-700 w-full">
        <Tab.Group>          
          <Tab.List className={'flex overflow-x-auto -mb-px text-sm text-center text-gray-500 dark:text-gray-400 border-b-2 bg-white'}>
            {
              windowWidth < 768 &&
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button className={`tab-btn ${selected && 'dark:text-blue-500 text-blue-600 border-blue-600 hover:border-blue-600 hover:text-blue-600 active dark:border-blue-500 whitespace-nowrap'}`} role='tab'>
                  Хичээлийн агуулга 
                </button>
                )}
              </Tab>
            }
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={`tab-btn ${selected && 'dark:text-blue-500 text-blue-600 border-blue-600 hover:border-blue-600 hover:text-blue-600 active dark:border-blue-500'}`} role='tab'>
                  <div className='flex'>
                    <ion-icon name="information-circle-outline" style={{fontSize:'16px', marginRight: '2px'}}/>
                  </div>
                  <div>Танилцуулга</div>
              </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={`tab-btn ${selected && 'dark:text-blue-500 text-blue-600 border-blue-600 hover:border-blue-600 hover:text-blue-600 active dark:border-blue-500'}`} role='tab'>
                  <div className='flex'>
                    <ion-icon name="help-outline" style={{fontSize:'16px', marginRight: '2px'}}></ion-icon>
                  </div>
                  <div className='whitespace-nowrap'>Асуулт Хариулт</div>
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button  className={`tab-btn ${selected && 'dark:text-blue-500 text-blue-600 border-blue-600 hover:border-blue-600 hover:text-blue-600 active dark:border-blue-500'}`} role='tab'>
                  <div className='flex'>
                    <ion-icon name="megaphone-outline" style={{fontSize:'16px', marginRight: '5px'}}></ion-icon>
                  </div>
                  Зарлал
                </button>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <button className={`tab-btn ${selected && 'dark:text-blue-500 text-blue-600 border-blue-600 hover:border-blue-600 hover:text-blue-600 active dark:border-blue-500'}`} role='tab'>
                  <div className='flex'>
                    <ion-icon name="star-half-outline" style={{fontSize:'16px', marginRight: '5px'}}></ion-icon> 
                  </div>
                  Үнэлгээ
                </button>
              )}
            </Tab>
          </Tab.List>
          <Tab.Panels className='max-w-7xl mx-auto px-3 py-5'>
            {
              windowWidth < 768 &&
              <Tab.Panel>
                <ContentSidebar 
                  handleChangeVideo={handleChangeVideo} 
                  currentVideo={currentVideo} 
                  videoEnded={videoEnded} 
                  handleVideoEnded={handleVideoEnded} 
                  handleTest={handleTest}
                  handleExamModal={handleExamModal}
                />
              </Tab.Panel>
            }
            <Tab.Panel>
              <div className='text-xl font-medium text-sky-500 mb-2'>{data.title}</div>
              <div className='text-xl mb-3' >Сургалтын танилцуулга</div>
              <div className='prose prose-md contents dark:text-white text-sm' dangerouslySetInnerHTML={{__html: data.descr}}></div>
            </Tab.Panel>
            <Tab.Panel>
              <QA questions={data.course_questions} data={data} refreshData={refresh}/>
            </Tab.Panel>
            <Tab.Panel> 
              <div className='text-xl mb-3' >Зарлал</div>
              {
                data.course_announcements.map((announcement, i) => (
                  <div className='border p-4 rounded' key={i}>
                    <div className='flex mb-4'>
                      <img 
                        src={`${process.env.REACT_APP_ASSET_URL}${data.image}`} 
                        style={{width:'40px', height:'40px', objectFit:'cover', objectPosition:'center', borderRadius:'50%', overflow:'hidden'}}
                        onError={(e)=>{e.target.onerror = null; e.target.src=`${ImgErrorEmployee}`}} 
                        className='mr-3'
                      />
                      <div>
                        <div className='text-sm text-gray-500'>{announcement.lastName} {announcement.firstName}</div>
                        <div className='font-medium'>{announcement.title}</div>
                      </div>
                    </div>
                    <div className='card'>
                      <div className='prose prose-md contents dark:text-white text-sm' dangerouslySetInnerHTML={{__html: announcement.body}}></div>
                    </div>
                  </div>
                ))
              }
            </Tab.Panel>
            <Tab.Panel> 
              <Review data={data} refreshData={refresh}/>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  )
}

export default Details